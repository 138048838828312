import "../legal.css";

import * as React from "react";
import { useTranslation } from "react-i18next";
// import { Trans } from "react-i18next"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { Parallax } from "react-scroll-parallax";

import Layout from "../../../components/layout/Layout";
import Seo from "../../../components/seo";
import SectionLanding from "../../../components/section/SectionLanding";
import Mask3 from "../../../svg/masks/mask3.svg";
import { Link } from "gatsby-plugin-react-i18next";

export default function IndexPage() {
	const { t } = useTranslation();
	return (
		<Layout>
			<Seo title={t("Política RRSS")} />
			<StaticImage
				src={`../../../images/contacto.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../../images/contacto.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<Mask3 />

			<SectionLanding title={t("Política RRSS")} />

			<div className="legalBody section invertColors" id="politicaRRSS">
				<h2>1. Identificación del Responsable del Tratamiento</h2>
				<p>
					Identidad - Entidad : BEGIN RESTAURANTES, S.L.
					<br />
					CIF/NIF: B40626392
					<br />
					Dirección postal: Av. Cortes Valencianas, 50, CP 46015 Valencia
					(Valencia)
					<br />
					Correo electrónico: rrhh@beginrestaurante.com
					<br />
					Objeto Social: Restauración
					<br />
					Sitio web: https://beginrestaurante.com/
					<br />
					Datos registrales: Inscrita en el Registro Mercantil de Valencia, Tomo
					10754, Libro 8034, Folio 132, Sección 8, Hoja 190498, Inscripción 11.
					<br />
				</p>
				<h2>2. Presencia en redes sociales</h2>
				<p>
					En cumplimiento de los artículos 13 al 24.2 del Reglamento Europeo de
					Protección de Datos 2016/679 (RGPD) y la Ley 34/2002 de Servicios de
					la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), el
					Responsable del Tratamiento informa a los usuarios, que ha procedido a
					crear un perfil en las Redes Sociales (RRSS) siguientes:
					<br />·{" "}
					<a href="https://www.instagram.com/beginrestaurante/">Instagram</a>
					<br />· <a href="https://www.tiktok.com/@beginrestaurante">Tik Tok</a>
					<br />
					con la finalidad principal de publicitar nuestros servicios y
					contactar con otros usuarios.
				</p>
				<p>
					Si Usted como Usuario dispone de un perfil en la misma Red Social que
					nuestra entidad, puede libremente unirse a la página creada por
					nosotros, mostrando así interés en la información que publicamos en
					dicha Red.
					<br />
					Al unirse, nos ofrece visibilidad de ciertos datos personales
					ofrecidos públicamente por Usted en su perfil. <br />
					<br />
					Solo en caso de que se una a nuestra página de las Redes Sociales
					detalladas, los datos de su perfil serán tratados por nuestra entidad
					únicamente dentro de la propia Red Social y en ningún caso serán
					tratados por nosotros fuera de esta sin su consentimiento previo.
					<br />
					Usted como usuario, puede acceder en todo momento a las políticas de
					privacidad de la propia Red Social, así como configurar su perfil para
					garantizar la privacidad de aquellos datos que no quiera hacer
					públicos. También puede dejar de seguirnos en el momento que lo desee.
				</p>
				<h2>3. Publicaciones</h2>
				<p>
					Una vez se haya unido a nuestra página y si lo estima oportuno, podrá
					publicar comentarios, enlaces, imágenes, fotografías o cualquier otro
					tipo de contenido multimedia soportado por la Red Social, recordándole
					que, en todos los casos, debe ser el titular de éstos, gozar de los
					derechos de autor y de propiedad intelectual o contar con el
					consentimiento de los terceros afectados.
					<br />
					Se prohíbe expresamente cualquier publicación en la página, ya sean
					textos, gráficos, fotografías, vídeos, etc. relacionados con menores,
					aquellos que atenten o sean susceptibles de atentar contra el honor,
					la moral, la ética, el buen gusto, el decoro y/o que infrinjan, violen
					o quebranten los derechos de propiedad intelectual o industrial, el
					derecho a la imagen o cualquier otro que vaya contra la Ley. En estos
					casos, nuestra entidad se reserva el derecho a retirar de inmediato el
					contenido, pudiendo solicitar el bloqueo permanente del usuario.
					<br />
					Nuestra entidad no se hará responsable de los contenidos que
					libremente ha publicado un usuario. El usuario debe tener presente que
					sus publicaciones serán conocidas por otros usuarios, por lo que será
					el único responsable de sus publicaciones y de su privacidad.
				</p>

				<h2>4. Publicidad</h2>
				<p>
					Nuestra entidad podrá utilizar la Red Social para publicitarse, en
					todo caso, cumpliendo con las exigencias de la Normativa vigente
					aplicable a Protección de Datos y de la LSSI-CE.
					<br />
					No se considerará publicidad el hecho de recomendar a otros usuarios
					nuestras páginas para que también ellos puedan disfrutar de las
					promociones o estar informados de eventos o actividades de nuestra
					entidad.
				</p>

				<h2>5. Concursos y promociones</h2>
				<p>
					Nuestra entidad se reserva el derecho a realizar concursos y
					promociones, en los que podrán participar los usuarios que lo deseen y
					que estén unidos a nuestra página. Las bases de cada uno de ellos,
					cuando se utilice para ello la plataforma de la Red Social, serán
					publicadas en la misma, cumpliendo siempre con la Ley o norma que le
					sea de aplicación.
					<br />
					La Red Social no patrocina, avala ni administra, de modo alguno
					nuestras promociones, ni está asociada a ninguna de ellas.
				</p>

				<h2>6. Datos personales</h2>
				<p>
					Nuestra entidad como Responsable del Tratamiento, realizará las
					siguientes actuaciones: <br />
					o Acceso a la información pública del perfil del usuario. <br />
					o Atender solicitudes de los usuarios. <br />
					o Envío de mensajes individuales al usuario por medio de los canales
					de la Red Social. <br />
					o Publicación en el perfil del usuario de información relacionada con
					nuestra entidad. <br />o Actualizaciones del estado de la página que
					se publicarán en el perfil del usuario <br />
					El usuario puede dejar de seguir a nuestra entidad en las redes
					sociales en cualquier momento, controlar sus conexiones, eliminar los
					contenidos que dejen de interesarle y restringir con quien comparte
					sus conexiones, para ello deberá acceder a su configuración de
					privacidad.
				</p>

				<p>
					A continuación, detallamos los enlaces a la política de privacidad de
					las diferentes Redes Sociales: <br />
					Instagram:{" "}
					<a href="https://es-la.facebook.com/help/instagram/155833707900388/">
						https://es-la.facebook.com/help/instagram/155833707900388/
					</a>
					<br />
					Tik Tok:{" "}
					<a href="https://www.tiktok.com/legal/page/eea/privacy-policy/es">
						https://www.tiktok.com/legal/page/eea/privacy-policy/es
					</a>
				</p>

				<h2>7. Sus Derechos</h2>
				<p>
					Usted tiene derecho a conocer si nuestra entidad está tratando sus
					datos personales; por tanto, tiene derecho a acceder a sus datos,
					rectificarlos si son inexactos o solicitar su supresión cuando los
					datos ya no sean necesario, siempre que sea legalmente posible.
					<br />
					También puede ejercer su derecho de oposición, limitación o
					portabilidad si así lo estima conveniente, incluso podrá revocar su
					consentimiento y para ejercer sus derechos deberá hacerlo por escrito
					a{" "}
					<a href="mailto:contacto@beginrestaurante.com">
						contacto@beginrestaurante.com
					</a>
					, acompañando una copia de su DNI o documento equivalente para para
					facilitarnos su identificación.
					<br />
					Si quien desea ejercer sus derechos es menor de 14 años, podrá hacerlo
					a través de sus padres, tutores o representante legal.
				</p>
				<p>
					Si desea trasladar alguna sugerencia o consulta sobre el tratamiento
					de sus datos personales, puede contactar con nuestros consultores de
					protección de datos, indicando el nombre de nuestra empresa o el
					nombre comercial: <br />
					BUSINESS ADAPTER, S.L. <br />
					Ronda Guglielmo Marconi, 11, 26, (Parque Tecnológico) 46980 Paterna
					(Valencia). <br />
					<a href="https://www.businessadapter.es/contacta-con-el-delegado-de-proteccion-de-datos">
						Formulario de atención al interesado
					</a>
				</p>

				<p>
					Le informamos que si usted en tiende que sus derechos han sido
					desatendidos, tiene derecho a realizar una reclamación ante la Agencia
					Española de Protección de Datos en: C/ Jorge Juan, 6, 28001 Madrid o
					en <a href="www.aepd.es">www.aepd.es</a>.
				</p>

				<p>
					Para obtener más información sobre el tratamiento de datos personales,
					puede consultar nuestra
					<Link to="/legal/pollitica-privacidad">Política de Privacidad</Link>.
				</p>

				<h2>8. Actualización de esta Política</h2>
				<p>
					Nuestra entidad se reserva el derecho a la modificación de esta
					Política sin previo aviso. Es por ello, que recomendamos consultarla
					cada vez que visite nuestra web.
				</p>

				<p style={{ textAlign: "right" }}>
					Actualizado el 6 de octubre de 2023.
				</p>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
